exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-users-luca-documents-progetti-michele-pagano-gatsby-starter-portfolio-jodie-content-pages-mp-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/Users/luca/Documents/Progetti/michele-pagano/gatsby-starter-portfolio-jodie/content/pages/mp/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-users-luca-documents-progetti-michele-pagano-gatsby-starter-portfolio-jodie-content-pages-mp-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-luca-documents-progetti-michele-pagano-gatsby-starter-portfolio-jodie-content-price-list-color-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/Users/luca/Documents/Progetti/michele-pagano/gatsby-starter-portfolio-jodie/content/price-list/color/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-luca-documents-progetti-michele-pagano-gatsby-starter-portfolio-jodie-content-price-list-color-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-luca-documents-progetti-michele-pagano-gatsby-starter-portfolio-jodie-content-price-list-cut-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/Users/luca/Documents/Progetti/michele-pagano/gatsby-starter-portfolio-jodie/content/price-list/cut/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-luca-documents-progetti-michele-pagano-gatsby-starter-portfolio-jodie-content-price-list-cut-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-luca-documents-progetti-michele-pagano-gatsby-starter-portfolio-jodie-content-price-list-style-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/Users/luca/Documents/Progetti/michele-pagano/gatsby-starter-portfolio-jodie/content/price-list/style/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-luca-documents-progetti-michele-pagano-gatsby-starter-portfolio-jodie-content-price-list-style-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-luca-documents-progetti-michele-pagano-gatsby-starter-portfolio-jodie-content-price-list-treatments-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/Users/luca/Documents/Progetti/michele-pagano/gatsby-starter-portfolio-jodie/content/price-list/treatments/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-users-luca-documents-progetti-michele-pagano-gatsby-starter-portfolio-jodie-content-price-list-treatments-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

