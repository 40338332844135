module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Michele Pagano - Care Cut Color","short_name":"Michele Pagano","description":"Michele Pagano hair stylist in Cesena. Create hair styles for women and man with competitive price and following newer trends.","start_url":"/","background_color":"#ffffff","display":"standalone","icons":[{"src":"/michelepagano-logo-180x180.png","sizes":"180x180","type":"image/png"},{"src":"/michelepagano-logo-180x180.png","sizes":"180x180","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
